<template>
  <div>
      <div v-for="(detail, detailKey) of contact" :key="detailKey" class="row no-gutters">
          <div class="col-10 col-md-7" v-html="detail.label"></div>
          <div class="col" v-html="detail.value"></div>
      </div>
  </div>
</template>

<script>
    export default {
        name: 'AddrLegal',
        props:['contact'],
        watch: {
            realUser: function(newValue) {
                if(newValue) {
                    setTimeout(() => {
                        window.jQuery('.handle--data').each((elindex, el) => {
                            elindex
                            let tmpData = window.jQuery(el).data()
                            if(typeof (tmpData['info']) !== 'undefined') {
                                window.jQuery(el).html(this.cleanupData(tmpData['info']))
                                window.jQuery(el).attr('data-info', '')
                            }
                            if(typeof (tmpData['href']) !== 'undefined') {
                                window.jQuery(el).attr('href', this.cleanupData(tmpData['href']))
                                window.jQuery(el).attr('data-href', '')
                            }
                            console.log()
                        });
                    }, 10)
                }
            }
        },
        data: function() {
            return {
                realUser: false,
            };
        },
        methods: {
            detectRealUser: function() {
                if(!this.realUser) {
                    const windowEl = window.$(document)[0]
                    let makeEmailShow = () => {
                        this.realUser = true
                        windowEl.removeEventListener('mousemove', makeEmailShow)
                        windowEl.removeEventListener('click', makeEmailShow)
                        windowEl.removeEventListener('scroll', makeEmailShow)
                    }
                    windowEl.addEventListener('click', makeEmailShow)
                    windowEl.addEventListener('scroll', makeEmailShow)
                    windowEl.addEventListener('mousemove', makeEmailShow)
                }
            },
            cleanupData: function(str) {
                return str.split('[rem]').join('').split('[at]').join('@').split('[dot]').join('.')
            }
        },
        updated() {
            this.detectRealUser()
        }
    };
</script>
